import React from "react";
import { Box, Image } from "@chakra-ui/react";
import roadMap from "./assets/roadmap.png";
export default function SectionRoadmap() {
  return (
    <Box id="roadmap" height="100vh">
      <Box fontSize="40px" padding="0 0 0 1rem">
        Roadmap
      </Box>
      <Box fontSize="40px" padding="4rem 0 4rem 0" display="flex" justify>
        <Image
          display="block"
          marginX="auto"
          maxW="80vw"
          maxH="45vw"
          src={roadMap}
          alt="Dan Abramov"
        ></Image>
      </Box>
    </Box>
  );
}
