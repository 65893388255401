import { React } from "react";
import { Box, Button, Flex,  Spacer } from "@chakra-ui/react";
// import Facebook from "./assets/social-media-icons/facebook_32x32.png";
// import Twitter from "./assets/social-media-icons/twitter_32x32.png";
// import Email from "./assets/social-media-icons/email_32x32.png";

const NavBar = ({ accounts, setAccounts }) => {
  const isConnected = Boolean(accounts[0]);

  async function connectAccount() {
    // console.log("window.ethereum", window.ethereum);
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      // console.log('accounts', accounts, '=====');
      setAccounts(accounts);
      
    }
  }
  
  return (
    <Flex justify="end" align="center" padding="1rem">
      {/* Left Side -- Social Media Icons */}
      {/* <Flex justify="space-around" width="40%" padding="0 75px">
        <Link href="https://www.facebook.com">
          <Image src={Facebook} boxSize="42px" margin="0 15px"></Image>
        </Link>
        <Link href="https://www.twitter.com">
          <Image src={Twitter} boxSize="42px" margin="0 15px"></Image>
        </Link>
        <Link href="https://www.gmail.com">
          <Image src={Email} boxSize="42px" margin="0 15px"></Image>
        </Link>
      </Flex> */}
      {/* Right Side - Sections AND Connect */}
      <Flex
        justify="space-around"
        align="center"
        width="60%"
        padding="30px 30px 30px 30px"
      >
        <a href="#main" margin="0 0">Mint</a>
        <Spacer />
        <a href="#aboutus" margin="0 0">About us</a>
        <Spacer />
        <a href="#background" margin="0 0">Background</a>
        <Spacer />
        <a href="#roadmap" margin="0 0">Roadmap</a>
        <Spacer />
        {/* Connect */}
        {isConnected ? (
          <Box margin="0 15px">Connected</Box>
        ) : (
          <Button
            backgroundColor="red"
            borderRadius="5px"
            boxShadow="0px 2px 2px 1px #0F0F0F"
            color="white"
            cursor="pointer"
            fontFamily="inherit"
            padding="15px"
            margin="0 15px"
            onClick={connectAccount}
          >
            Connect
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default NavBar;
