import "./App.css";
import { useState } from "react";
import MainMint from "./MainMint";
import NavBar  from "./NavBar";
import SectionAboutUs from "./SectionAboutUs";
import SectionBackground from './SectionBackground'
import SectionRoadmap from './SectionRoadmap'
function App() {
  const [accounts, setAccounts] = useState([]);

  return (
    <div className="overlay">
      <div className="App">
        <NavBar accounts={accounts} setAccounts={setAccounts} />
        <MainMint accounts={accounts} setAccounts={setAccounts} />
        <SectionAboutUs />
        <SectionBackground />
        <SectionRoadmap/>
      </div>
      <div className="moving-background"></div>
      
    </div>
  );
}

export default App;
