import React from "react";
import { Flex, Box,Image } from "@chakra-ui/react";
// import img from "./assets/img.png";
// import { Image } from "@chakra-ui/react";
export default function SectionAboutUs() {
  return (
    <Flex id="background"  height="100vh">
      {/* <Image h="80vh" src={img} alt="img"></Image> */}
      <Box padding={"0 0 0 2rem"} w={"50vw"}>
        <Box fontSize="3vw">Background</Box>
        <Box padding={"2vh 0 0 0"} paddingLeft={"2rem"} fontSize="1vw">
          Punk trendsetter in the etheric ecosystem, unexplored because of a
          loophole they jumped out, they saw the glory of encryption punk, but
          they are not content to look at encryption punk, they began to prepare
          for, and will continue to launch our own trend, I hope one day can
          catch up with the encryption punk, even beyond it, Create a very
          brilliant more dazzling punk trend era.
        </Box>
      </Box>
    </Flex>
  );
}
