import React from "react";
import {  Box, Flex,Image } from "@chakra-ui/react";
import aboutUs from "./assets/about us.jpg";

// const members = [
//   "Name: JAMS <br>Gender: male <br>Occupation: engineer, <br>Interests: motorcycle boxing shooting, <br>Personal attributes: A Love Punk-style Technology Nerd",
//   "Name: Tang <br>Gender: male <br>Occupation: Art Director <br>Interests: Locomotive Painting Design Football <br>Personality: a very good taste of middle-aged uncle",
//   "Name: Tom <br>Gender: male <br>Occupation: freelance artist <br>Hobbies: Motorcycle Graffiti Anime Skateboard <br>Personal Attributes: a cool street youth",
//   "Name: Li <br>Gender: male <br>Occupation: Game Talent <br>Interests: motorcycle video game online literature made <br>Personal attributes: a bit of social fear of the senior fans of the game",
// ];

export default function SectionAboutUs() {
  return (
    <>
      <Box padding={"0 0 0 2rem"} id="aboutus" fontSize="40px">About Us</Box>
      <Flex height="100vh" marginTop="1rem" justify="space-around">
        {/* <Box width="40vw" my={"0"}>
          
        </Box> */}
        <Image
          display="block"
          marginX="auto"
          maxW="80vw"
          maxH="45vw"
          src={aboutUs}
          alt="Dan Abramov"/>
      </Flex>
    </>
  );
}
